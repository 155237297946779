<template>
  <div class="empty-layout">
    <Header />
    <div class="empty-layout__content">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<style lang="postcss" scoped>
.empty-layout__content {
  padding: 15px 20px 60px;

  @media (max-width: 1000px) {
    padding: 15px 15px 25px;
  }
}
</style>
